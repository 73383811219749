import RSeo from "@components/resultsseo"
import useUrlData from "@components/SearchResults/hooks/useUrlData"
import Seo from "@components/seo"
import { createResultsCanonicalUrl, getH1Text, getSeoDesc, parseResultsUrl } from "@lib/searchUtils"
import "@styles/search-results.scss"
import SearchResultsPage from "@templates/search-results-template"
import React, { useEffect, useState } from "react"
import logo from "@images/logo.svg"
import { graphql, useStaticQuery } from "gatsby"
import useUrlDataSeo from "@components/SearchResults/hooks/useUrlDataSeo"
const { useLocation } = require("@reach/router")
const SearchResults = (props) => {

  
  return <SearchResultsPage {...props} />
}

export const Head = (props) => {
  const data = useStaticQuery(graphql`
    query {
          allProperties(filter: {publish: {eq: true}, search_type: {eq: "sales"}}) {
        totalCount
      }
    }
  `)
  let { pathname } = useLocation()
  const pageUrlData = parseResultsUrl(props?.location)
  const h1Text = getH1Text(pageUrlData)
  const seoDesc = getSeoDesc(pageUrlData)
  const [customCanonical, setCustomCanonical] = useState(process.env.GATSBY_SITE_URL + pathname)
      const pageurl = typeof window !== "undefined" ? window.location.href : ""
      const listingpage = (typeof window !== "undefined" && window.location.href)
  var ldJson = {
    "@context": "https://schema.org/",
    "@id": "SearchResultsPage",
    "@type": [
      "SearchResultsPage",
      "Product"
    ],
    "accessModeSufficient": {
      "@type": "ItemList",
      "name": h1Text,
      "itemListOrder": "https://schema.org/ItemListOrderDescending",
      "numberOfItems": data?.allProperties?.totalCount,
      "itemListElement": []
    },
    "brand": {
      "@type": "Organization",
      "name": "Provident Estate"
    },
    "breadcrumb": {
      "@id": pageurl+"#breadcrumb",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "name": "Provident Estate",
          "item": process.env.GATSBY_SITE_URL,
          "position": 1
        },
        {
          "@type": "ListItem",
          "name": h1Text,
          "item": listingpage,
          "position": 2
        }
      ]
    },
    "contentLocation": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Dubai"
      }
    },
    "description": seoDesc,
    "image": "https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/x.dev/1773x/888_4af09da9b9.webp",
    "name": h1Text,
    "offers": {
      "@type": "AggregateOffer",
      "highPrice": "999999999",
      "lowPrice": "100000",
      "offerCount": data?.allProperties?.totalCount,
      "priceCurrency": "AED"
    },
    "sameAs": "https://en.wikipedia.org/wiki/Search_engine_results_page",
    "speakable": {
      "@type": "SpeakableSpecification"
    }
   

  }

  var ldjson1 = {
    "@context": "http://schema.org",
    "@id": "Organization",
    "@type": [
      "Organization",
      "Corporation"
    ],
    "logo": logo,
    "url": process.env.GATSBY_SITE_URL,
    "brand": {
      "@type": "Brand",
      "name": "Provident Estate"
    },
    "name": "Provident Estate",
    "address": "#1802, 18th Floor, Marina Plaza, Dubai Marina, Dubai, UAE",
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+971505390249",
      "contactType": "customer service",
      "areaServed": "ae",
      "availableLanguage": [
        "en",
        "ar"
      ]
    },
    "sameAs": [
      "https://www.facebook.com/providentestate",
      "https://www.instagram.com/providentestate",
      "https://www.linkedin.com/company/providentestate",
      "https://www.youtube.com/@Providentestate",
      "https://twitter.com/providentagents"
    ]
  }
  useEffect(() => {
    const fetchCanonicalUrl = async () => {
      const canonicalUrl = await createResultsCanonicalUrl({ pageUrlData })
      setCustomCanonical(process.env.GATSBY_SITE_URL+canonicalUrl)
    }

    fetchCanonicalUrl()
  }, [pathname, h1Text])
  if (!customCanonical) {
    // You can return a loading state or default canonical URL here if needed
    return null
  }

  return  <>
  <link crossorigin href="https://d4vban0ooq-2.algolianet.com" rel="preconnect" />

  <RSeo 
  customCanonical={customCanonical || process.env.GATSBY_SITE_URL + pathname}
   title={h1Text} description={seoDesc} isproperty ldJson={ldJson} ldjson1={ldjson1} />
   </>
}

export default SearchResults
